<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Cards</span>
        </h3>
      </div>
      <div class="card-body py-0">
        <div class="row mb-5">
          <div class="col-md-4">
            <div class="form-group mb-0">
              <input
                v-model="serialNumber"
                type="text"
                class="form-control"
                placeholder="Serial number"
              />
            </div>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-block btn-light-primary font-weight-bold"
              @click="getCards"
            >
              {{ $t('GENERAL.SEARCH') }}
            </button>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-3 d-flex justify-content-end">
            <!--begin::Button-->
            <router-link
              to="/loyalty/cards/new"
              class="btn btn-primary font-weight-bolder"
            >
              <span class="svg-icon svg-icon-md">
                <inline-svg
                  src="media/svg/icons/Shopping/Credit-card.svg"
                /> </span
              >Add New
            </router-link>
          </div>
        </div>
      </div>

      <div class="card-body py-0">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-left text-uppercase">
                <th class="px-6">
                  <span @click="orderCardBySerialNumber" class="cursor-pointer">
                    <span
                      class="pt-2"
                      :class="{
                        'text-primary': cardSerialNumberOrder.isActive,
                      }"
                    >
                      Serial number</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': cardSerialNumberOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="cardSerialNumberOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span @click="orderCardByOwner" class="cursor-pointer">
                    <span
                      class="pt-2"
                      :class="{
                        'text-primary': cardOwnerOrder.isActive,
                      }"
                    >
                      Owner</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': cardOwnerOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="cardOwnerOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th>User</th>
                <th>
                  <span @click="orderCardBySaldo" class="cursor-pointer">
                    <span
                      class="pt-2"
                      :class="{
                        'text-primary': cardSaldoOrder.isActive,
                      }"
                    >
                      Saldo</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': cardSaldoOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="cardSaldoOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th>Is Verified</th>
                <th>Is Active</th>
                <th class="text-right pr-6">Options</th>
              </tr>
            </thead>
            <tr v-if="!isCardsLoaded">
              <td colspan="7" style="width: 100%; text-align: center">
                <b-spinner
                  class="mt-5"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </td>
            </tr>
            <tbody v-else>
              <tr v-for="(card, key) in cards" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    :to="'/loyalty/cards/edit/' + card.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ card.serialNumber }}</router-link
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span v-if="card.owner">{{ card.owner.name }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span v-if="card.userFullName">{{ card.userFullName }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span v-if="card.saldo > 0">{{
                    card.saldo | currencyFormat
                  }}</span>
                  <span v-else>0,00</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span
                    v-if="card.isVerified"
                    class="label label-rounded label-primary px-5"
                    >{{ $t('GENERAL.YES') }}</span
                  >
                  <span v-else class="label label-rounded label-danger px-5">{{
                    $t('GENERAL.NO')
                  }}</span>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <span
                    v-if="card.isActive"
                    class="label label-rounded label-primary px-5"
                    >{{ $t('GENERAL.YES') }}</span
                  >
                  <span v-else class="label label-rounded label-danger px-5">{{
                    $t('GENERAL.NO')
                  }}</span>
                </td>
                <td
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <button
                    @click="toggleCardTransactionsModal(card.serialNumber)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/Text/Align-justify.svg"
                      />
                    </span>
                  </button>
                  <button
                    @click="generateCardQrCodeUrl(card.serialNumber)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Design/Position.svg" />
                    </span>
                  </button>
                  <router-link
                    :to="'/loyalty/cards/edit/' + card.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <a
                    @click.prevent="deleteEntity(card.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
          >
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="pagination.totalPages"
              use-router
              size="lg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal id="card_modal" hide-header hide-footer>
          <div>
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('card_modal')"
              >Close</b-button
            >
            <div style="text-align: center">
              <VueQrcode
                style="position: relative"
                :value="qrCodeUrl"
                :options="{ width: 250 }"
                class="qr-code-modal__code"
              ></VueQrcode>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal id="card_saldo_modal" hide-header hide-footer>
          <div>
            <div class="row">
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('card_saldo_modal')"
                >Close</b-button
              >
              <div class="col-12">
                <div class="form-group">
                  <label>Saldo</label>
                  <input class="form-control form-control-solid" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="btn btn-block btn-primary">Update Saldo</button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="card_transactions_modal"
          size="xl"
          hide-header
          hide-footer
          centered
        >
          <div>
            <button
              class="btn btn-light-primary btn-block mb-3"
              @click="$bvModal.hide('card_transactions_modal')"
            >
              Close
            </button>
            <div>
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th class="pl-6">
                      <span>{{ $t('GENERAL.DEVICE') }}</span>
                    </th>
                    <th>
                      <span>{{ $t('GENERAL.LOCATION') }}</span>
                    </th>
                    <th><span>Imei</span></th>
                    <th>
                      <span>{{ $t('GENERAL.AMOUNT') }}</span>
                    </th>
                    <th class="text-right pr-6"><span>Date & Time</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(transaction, key) in cardTransactions" :key="key">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                        >{{ transaction.deviceMeta.name }}</span
                      >
                      <div>
                        <span
                          v-for="(tag, i) in transaction.locationMeta.tags"
                          :key="i"
                          class="label label-sm label-light-primary label-inline mr-2"
                          >{{ tag }}</span
                        >
                      </div>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{ transaction.locationMeta.title }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{ transaction.imei }}</span>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span class="font-weight-bold">{{
                        parseFloat(transaction.amount) | currencyFormat
                      }}</span>
                    </td>
                    <td
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{ transaction.createdAt | formatDateTime }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import ApiService from '../../../../core/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'LoyaltyCardList',
  components: {
    VueQrcode,
  },
  data() {
    return {
      cards: [],
      isCardsLoaded: false,
      qrCodeUrl: null,
      cardTransactions: [],
      isCardTransactionsLoaded: false,

      cardSerialNumberOrder: {
        query: 'desc',
        isActive: false,
      },
      cardOwnerOrder: {
        query: 'desc',
        isActive: false,
      },
      cardSaldoOrder: {
        query: 'desc',
        isActive: false,
      },
      pagination: {
        totalPages: 0,
        totalItems: 0,
        currentPage: 1,
        itemsPerPage: 30,
      },
      serialNumber: '',
    }
  },
  computed: {
    ...mapGetters(['currentUserCompany']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty All Cards', route: '' },
    ])
    this.getCards()
  },
  watch: {
    '$route.query.page'(newPage) {
      this.pagination.currentPage = newPage || 1
      this.getCards()
    },
  },
  methods: {
    getCards() {
      let searchQuery = ''
      if (this.serialNumber) {
        searchQuery = `&serialNumber=${this.serialNumber}`
      }
      axios
        .get(
          `https://loyalty.vendingtycoon.eu/api/v1/cards?page=${this.pagination.currentPage}&itemsPerPage=${this.pagination.itemsPerPage}${searchQuery}`
        )
        .then(({ data }) => {
          this.cards = [...data['hydra:member']]
          this.isCardsLoaded = true
          this.pagination.totalItems = data['hydra:totalItems']
          this.pagination.totalPages = Math.ceil(
            this.pagination.totalItems / this.pagination.itemsPerPage
          )
        })
    },
    showMoreInfoModal() {
      this.$root.$emit('bv::show::modal', 'card_modal')
    },
    generateCardQrCodeUrl(serialNumber) {
      this.qrCodeUrl = `https://loyalty.vendingtycoon.eu/card/${serialNumber}`
      this.$root.$emit('bv::show::modal', 'card_modal')
    },
    toggleCardTransactionsModal(serialNumber) {
      this.cardTransactions = []
      this.isCardTransactionsLoaded = false

      ApiService.get(
        `transactions`,
        `?serial_card=${serialNumber}&order[createdAt]=desc&pagination=false&company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}`
      ).then(({ data }) => {
        this.cardTransactions = data['hydra:member']
        this.isCardTransactionsLoaded = true

        this.$root.$emit('bv::show::modal', 'card_transactions_modal')
      })
    },

    // cards order
    orderCardBySerialNumber() {
      this.cardSerialNumberOrder.isActive = true

      if (this.cardSerialNumberOrder.query === 'asc')
        this.cardSerialNumberOrder.query = 'desc'
      else this.cardSerialNumberOrder.query = 'asc'

      if (this.cardSerialNumberOrder.query === 'asc') {
        this.cards.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber))
      } else {
        this.cards.sort((a, b) => b.serialNumber.localeCompare(a.serialNumber))
      }
    },
    orderCardByOwner() {
      this.cardOwnerOrder.isActive = true

      if (this.cardOwnerOrder.query === 'asc')
        this.cardOwnerOrder.query = 'desc'
      else this.cardOwnerOrder.query = 'asc'

      if (this.cardOwnerOrder.query === 'asc') {
        this.cards.sort((a, b) => a.owner.name.localeCompare(b.owner.name))
      } else {
        this.cards.sort((a, b) => b.owner.name.localeCompare(a.owner.name))
      }
    },
    orderCardBySaldo() {
      this.cardSaldoOrder.isActive = true

      if (this.cardSaldoOrder.query === 'asc')
        this.cardSaldoOrder.query = 'desc'
      else this.cardSaldoOrder.query = 'asc'

      if (this.cardSaldoOrder.query === 'asc') {
        this.cards.sort((a, b) => a.saldo - b.saldo)
      } else {
        this.cards.sort((a, b) => b.saldo - a.saldo)
      }
    },

    deleteEntity(id) {
      ApiService.delete(
        'https://loyalty.vendingtycoon.eu/api/v1/cards/' + id
      ).then(() => {
        this.$router.push({ name: 'loyalty-cards-list' })
      })
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },
}
</script>

<style lang="scss">
.vt-card {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 85.6mm;
  height: 53.98mm;
  color: #fff;
  font: 22px/1 'Iceland', monospace;
  background: #23189a;
  border: 1px solid #1e1584;
  -webkit-border-radius: 10px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 10px;
  -moz-background-clip: padding;
  border-radius: 10px;
  background-clip: padding-box;
  overflow: hidden;
}
.bank-name {
  float: right;
  margin-top: 15px;
  margin-right: 30px;
  font: 800 28px 'Open Sans', Arial, sans-serif;
}
.chip {
  position: relative;
  z-index: 1000;
  width: 50px;
  height: 0px;
  margin-top: 50px;
  margin-bottom: 10px;
  margin-left: 30px;
  background: #fffcb1;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, #fffcb1),
    color-stop(100%, #b4a365)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(-45deg, #fffcb1 0%, #b4a365 100%);
  /* IE10+ */
  background: linear-gradient(135deg, #fffcb1 0%, #b4a365 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffcb1", endColorstr="#b4a365", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  border: 1px solid #322d28;
  -webkit-border-radius: 10px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 10px;
  -moz-background-clip: padding;
  border-radius: 10px;
  background-clip: padding-box;
  -webkit-box-shadow: 0 1px 2px #322d28,
    0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  -moz-box-shadow: 0 1px 2px #322d28,
    0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  box-shadow: 0 1px 2px #322d28, 0 0 5px 0 0 5px rgba(144, 133, 87, 0.25) inset;
  overflow: hidden;
}
.chip .side {
  position: absolute;
  top: 8px;
  width: 12px;
  height: 24px;
  border: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
}
.chip .side.left {
  left: 0;
  border-left: none;
  -webkit-border-radius: 0 2px 2px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 2px 2px 0;
  -moz-background-clip: padding;
  border-radius: 0 2px 2px 0;
  background-clip: padding-box;
}
.chip .side.right {
  right: 0;
  border-right: none;
  -webkit-border-radius: 2px 0 0 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px 0 0 2px;
  -moz-background-clip: padding;
  border-radius: 2px 0 0 2px;
  background-clip: padding-box;
}
.chip .side:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: inline-block;
  width: 100%;
  height: 0px;
  margin: auto;
  border-top: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
}
.chip .vertical {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 8.66666667px;
  height: 12px;
  border: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1),
    0 0 4px rgba(0, 0, 0, 0.1) inset;
}
.chip .vertical.top {
  top: 0;
  border-top: none;
}
.chip .vertical.top:after {
  top: 12px;
  width: 17.33333333px;
}
.chip .vertical.bottom {
  bottom: 0;
  border-bottom: none;
}
.chip .vertical.bottom:after {
  bottom: 12px;
}
.chip .vertical:after {
  content: '';
  position: absolute;
  left: -8.66666667px;
  display: inline-block;
  width: 26px;
  height: 0px;
  margin: 0;
  border-top: 1px solid #322d28;
  -webkit-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(144, 133, 87, 0.25) inset,
    0 0 5px rgba(144, 133, 87, 0.25), 0 0 4px rgba(0, 0, 0, 0.1);
}
.data {
  position: relative;
  z-index: 100;
  margin-left: 30px;
  text-transform: uppercase;
}
.data .pan,
.data .month,
.data .year,
.data .year:before,
.data .name-on-card,
.data .date {
  position: relative;
  z-index: 20;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px #000;
}
.data .pan:before,
.data .month:before,
.data .year:before,
.data .year:before:before,
.data .name-on-card:before,
.data .date:before,
.data .pan:after,
.data .month:after,
.data .year:after,
.data .year:before:after,
.data .name-on-card:after,
.data .date:after {
  position: absolute;
  z-index: -10;
  content: attr(title);
  color: rgba(0, 0, 0, 0.2);
  text-shadow: none;
}
.data .pan:before,
.data .month:before,
.data .year:before,
.data .year:before:before,
.data .name-on-card:before,
.data .date:before {
  top: -1px;
  left: -1px;
  color: rgba(0, 0, 0, 0.1);
}
.data .pan:after,
.data .month:after,
.data .year:after,
.data .year:before:after,
.data .name-on-card:after,
.data .date:after {
  top: 1px;
  left: 1px;
  z-index: 10;
}
.data .pan {
  position: relative;
  z-index: 50;
  margin: 0;
  letter-spacing: 1px;
  font-size: 26px;
}
.data .first-digits {
  margin: 0;
  font: 400 10px/1 'Open Sans', Arial, sans-serif;
}
.data .exp-date-wrapper {
  margin-top: 5px;
  margin-left: 64px;
  line-height: 1;
  *zoom: 1;
}
.data .exp-date-wrapper:before,
.data .exp-date-wrapper:after {
  content: ' ';
  display: table;
}
.data .exp-date-wrapper:after {
  clear: both;
}
.data .exp-date-wrapper .left-label {
  float: left;
  display: inline-block;
  width: 40px;
  font: 400 7px/8px 'Open Sans', Arial, sans-serif;
  letter-spacing: 0.5px;
}
.data .exp-date-wrapper .exp-date {
  display: inline-block;
  float: left;
  margin-top: -10px;
  margin-left: 10px;
  text-align: center;
}
.data .exp-date-wrapper .exp-date .upper-labels {
  font: 400 7px/1 'Open Sans', Arial, sans-serif;
}
.data .exp-date-wrapper .exp-date .year:before {
  content: '/';
}
.data .name-on-card {
  margin-top: 10px;
}
.lines-down:before {
  content: '';
  position: absolute;
  top: 80px;
  left: -200px;
  z-index: 10;
  width: 550px;
  height: 400px;
  border-top: 2px solid #392db2;
  -webkit-border-radius: 40% 60% 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 40% 60% 0 0;
  -moz-background-clip: padding;
  border-radius: 40% 60% 0 0;
  background-clip: padding-box;
  box-shadow: 1px 1px 100px #4035b2;
  background: #2d21a6;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #2d21a6)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.lines-down:after {
  content: '';
  position: absolute;
  top: 20px;
  left: -100px;
  z-index: 10;
  width: 350px;
  height: 400px;
  border-top: 2px solid #392db2;
  -webkit-border-radius: 20% 80% 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 20% 80% 0 0;
  -moz-background-clip: padding;
  border-radius: 20% 80% 0 0;
  background-clip: padding-box;
  box-shadow: inset -1px -1px 44px #4035b2;
  background: #2d21a6;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #2d21a6)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.lines-up:before {
  content: '';
  position: absolute;
  top: -110px;
  left: -70px;
  z-index: 2;
  width: 480px;
  height: 300px;
  border-bottom: 2px solid #392db2;
  -webkit-border-radius: 0 0 60% 90%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 60% 90%;
  -moz-background-clip: padding;
  border-radius: 0 0 60% 90%;
  background-clip: padding-box;
  box-shadow: inset 1px 1px 44px #4035b2;
  background: #4031b2;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 100%,
    #23189a 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(64, 49, 178, 0)),
    color-stop(100%, #23189a)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 100%,
    #23189a 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 44%,
    #23189a 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 49, 178, 0) 44%,
    #23189a 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(64, 49, 178, 0) 44%,
    #23189a 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(64, 49, 178, 0)", endColorstr="#23189a", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.lines-up:after {
  content: '';
  position: absolute;
  top: -180px;
  left: -200px;
  z-index: 1;
  width: 530px;
  height: 420px;
  border-bottom: 2px solid #4035b2;
  -webkit-border-radius: 0 40% 50% 50%;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 40% 50% 50%;
  -moz-background-clip: padding;
  border-radius: 0 40% 50% 50%;
  background-clip: padding-box;
  box-shadow: inset 1px 1px 44px #4035b2;
  background: #2d21a6;
  /* Old browsers */
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, rgba(45, 33, 166, 0)),
    color-stop(100%, #2d21a6)
  );
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 100%,
    #2d21a6 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* Opera 12+ */
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* IE10+ */
  background: radial-gradient(
    ellipse at center,
    rgba(45, 33, 166, 0) 44%,
    #2d21a6 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="rgba(45, 33, 166, 0)", endColorstr="#2d21a6", GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
</style>
